const form = {
  formId: "param-month36-form",
  formField: {
    ASQ3Communication_CutoffLB: {
      name: "ASQ3Communication_CutoffLB",
      label: "ASQ3 Communication: BLACK (<=)",
      type: "text",
      errorMsg: "Cutoff value is required.",
      invalidMsg: "Cutoff value is invalid.",
    },
    ASQ3Communication_CutoffUB: {
      name: "ASQ3Communication_CutoffUB",
      label: "ASQ3 Communication: WHITE (>=)",
      type: "text",
      errorMsg: "Cutoff value is required.",
      invalidMsg: "Cutoff value is required.",
    },
    type1Error: {
      name: "type1Error",
      label: "Type 1 Error, alpha [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Type 1 Error is required.",
      invalidMsg: "Type 1 Error is invalid.",
    },
    type2Error: {
      name: "type2Error",
      label: "Type 2 Error, beta [ 0.0 - 1.0 ]",
      type: "text",
      errorMsg: "Type 2 Error is required.",
      invalidMsg: "Type 2 Error is invalid.",
    },
  },
};

export { form as default };
