import { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { Permission } from "models/abac";
import { useAbac } from "react-abac";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

import initialValues from "pages/participants/manage/schemas/initialValues";

const collectionPathChildren = "children";
const collectionPathParents = "parents";
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_CHILD_RECORD":
      return {
        isPending: false,
        data: action.payload,
        success: `Records of Child ${action.payload.childIdx} was updated successfully.`,
        error: null,
      };
    case "DELETED_PARTICIPANT_RECORD":
      return {
        isPending: false,
        data: action.payload,
        success: `Deleted records of Participant ${action.payload.userName}.`,
        error: null,
      };
    case "CREATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created the user, ${action.payload.displayName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useParticipantsManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();
  const { logUserActivity } = useLogManager();
  const { updateDoc, serverTimestamp } = useDocument();
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const [tabValue, setTabValue] = useState(0);
  const [toggleButtonValue, setToggleButtonValue] = useState("nudges");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const activeParentId = useRef(null);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/participants/manage")) {
        throw operationInvalidError;
      }

      dispatchIfNotUnmounted({
        type: "INITIAL_USER",
        payload: initialValues,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "Participants";
  let modeSubmit = "Create";
  let modeFieldDisabled = false;
  let modePermission = Permission.VIEW_ADMIN_DASHBOARD;
  //let modeValidation = validations;

  const submitDeleteParentChildDocs = async (parentDocId, childDocId) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (
        userHasPermissions(Permission.DELETE_PARENT) &&
        userHasPermissions(Permission.DELETE_CHILD)
      ) {
        const updatedChildDoc = await updateDoc(
          collectionPathChildren,
          childDocId,
          {
            deletedAt: serverTimestamp(),
            deletedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity:
            "delete child parent [useParticipantsManager.submitDeleteParentChildDocs]",
          document: updatedChildDoc,
          timestamp: serverTimestamp(),
        });

        const updatedParentDoc = await updateDoc(
          collectionPathParents,
          parentDocId,
          {
            deletedAt: serverTimestamp(),
            deletedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity:
            "delete participant parent [useParticipantsManager.submitDeleteParentChildDocs]",
          document: updatedParentDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "DELETED_PARTICIPANT_RECORD",
          payload: updatedParentDoc.data,
        });
      }
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  };

  const submitUpdateParentChildDocs = async (
    parentData,
    childIdx,
    childData
  ) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedParentsDoc = await updateDoc(
          collectionPathParents,
          parentData.id,
          {
            children: parentData.data.children,
            mobile: parentData.data.mobile,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );
        await logUserActivity({
          uid: user.uid,
          activity:
            "update participant parent [useParticipantsManager.submitUpdateParentChildDocs]",
          document: updatedParentsDoc,
          timestamp: serverTimestamp(),
        });

        console.log(JSON.stringify(childData.id));
        console.log(JSON.stringify(childData.data.gender));
        const updatedChildrenDoc = await updateDoc(
          collectionPathChildren,
          childData.id,
          {
            dob: childData.data.dob,
            gender: childData.data.gender,
            nudges: childData.data.nudges,
            formnudges: childData.data.formnudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );
        await logUserActivity({
          uid: user.uid,
          activity:
            "update participant child [useParticipantsManager.submitUpdateParentChildDocs]",
          document: updatedChildrenDoc,
          timestamp: serverTimestamp(),
        });

        const idxPlusOne = parseInt(childIdx) + 1;
        updatedChildrenDoc.data.childIdx = idxPlusOne;
        dispatchIfNotUnmounted({
          type: "UPDATED_CHILD_RECORD",
          payload: updatedChildrenDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitUpdateNudges = async (childIdx, docId, nudges) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedDoc = await updateDoc(collectionPathChildren, docId, {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        await logUserActivity({
          uid: user.uid,
          activity: "update nudges [useParticipantsManager.submitUpdateNudges]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        const idxPlusOne = parseInt(childIdx) + 1;
        updatedDoc.data.childIdx = idxPlusOne;
        dispatchIfNotUnmounted({
          type: "UPDATED_CHILD_RECORD",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitUpdateFormNudges = async (childIdx, docId, formnudges) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedDoc = await updateDoc(collectionPathChildren, docId, {
          formnudges: formnudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        await logUserActivity({
          uid: user.uid,
          activity:
            "update form nudges [useParticipantsManager.submitUpdateFormNudges]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        const idxPlusOne = parseInt(childIdx) + 1;
        updatedDoc.data.childIdx = idxPlusOne;
        dispatchIfNotUnmounted({
          type: "UPDATED_CHILD_RECORD",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    //modeValidation,
    response,
    dispatchDismiss,
    dispatchError,
    selectedAccount,
    setSelectedAccount,
    submitUpdateNudges,
    submitUpdateFormNudges,
    submitUpdateParentChildDocs,
    submitDeleteParentChildDocs,
    tabValue,
    setTabValue,
    toggleButtonValue,
    setToggleButtonValue,
    activeParentId,
  };
};
