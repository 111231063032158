const form = {
  formId: "participant-form",
  formField: {
    userName: {
      name: "userName",
      label: "User Name / ID",
      type: "text",
      errorMsg: "User Name is required.",
      invalidMsg: "User Name is invalid.",
    },
    mobile: {
      name: "mobile",
      label: "Mobile",
      type: "text",
      errorMsg: "Mobile is required.",
      invalidMsg: "Mobile number is invalid.",
    },
    whatsappInvite: {
      name: "whatsappInvite",
      label: "whatsappInvite",
      type: "text",
      errorMsg: "WhatsApp Invite is required.",
      invalidMsg: "WhatsApp Invite is invalid.",
    },
    participantGroup: {
      name: "participantGroup",
      label: "Participant Group",
      type: "text",
      min1Msg: "Must have at least 1 participant group.",
      max10Msg: "Must have at most 10 participant groups.",
      errorMsg: "Participant Group is required.",
    },
    dob: {
      name: "dob",
      label: "Date of Birth",
      type: "text",
      min1Msg: "Must have at least 1 dob.",
      max10Msg: "Must have at most 10 dob.",
      errorMsg: "DOB is required.",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "text",
      min1Msg: "Must have at least one of the gender options.",
      max10Msg: "Must have at most one of the gender options.",
      errorMsg: "Please select the gender.",
    },
  },
};

const childForm = {
  formId: "child-form",
  formField: {
    childGroup: {
      name: "participantGroup",
      label: "Participant Group",
      type: "text",
      min1Msg: "Must have at least 1 participant group.",
      max10Msg: "Must have at most 10 participant groups.",
      errorMsg: "Participant Group is required.",
    },
    childDob: {
      name: "dob",
      label: "Date of Birth",
      type: "text",
      min1Msg: "Must have at least 1 dob.",
      max10Msg: "Must have at most 10 dob.",
      errorMsg: "DOB is required.",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "text",
      min1Msg: "Must have at least one of the gender options.",
      max10Msg: "Must have at most one of the gender options.",
      errorMsg: "Please select the gender.",
    },
  },
};

export { form as default, childForm };
