import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import { ErrorMessage, FieldArray } from "formik";

function ChildrenInformation({ mainForm, mainFormField }) {
  const { participantGroup, dob, gender } = mainFormField;
  //const { setFieldValue } = mainForm;
  //const [genderValue, setGenderValue] = useState("");

  const nudgesQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const { collectionData: nudgesData } = useCollectionSnapshot(
    "nudges",
    nudgesQueries
  );
  const groupOptions = nudgesData.map((nudgeData) => {
    return nudgeData.data.displayName;
  });

  const [selectedGroups, setSelectedGroups] = useState([null]);
  const [selectedDOBs, setSelectedDOBs] = useState([null]);
  const [selectedGenders, setSelectedGenders] = useState([null]);

  return (
    <Card id="child-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox>
          <MDTypography variant="h5">Child Information</MDTypography>
        </MDBox>
        <MDBox
          borderRadius="lg"
          sx={{
            p: 0,
            mt: 1,
            mb: 1,
            border: "1px solid grey",
            opacity: 0.5,
          }}
        ></MDBox>

        <MDBox>
          <FieldArray
            name="children"
            render={(arrayHelpers) => {
              const children = mainForm.values.children;
              return (
                <>
                  <MDBox mt={3}>
                    {children && children.length > 0
                      ? children.map((user, index) => (
                          <MDBox key={index} mb={6}>
                            <Grid
                              container
                              rowSpacing={{ xs: 1, sm: 2 }}
                              columnSpacing={{ xs: 1, sm: 3 }}
                            >
                              <Grid item xs={12}>
                                <MDBox
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                  >
                                    Child - {index + 1}
                                  </MDTypography>
                                  <MDButton
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    iconOnly
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <ClearIcon />
                                  </MDButton>
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <MDTypography variant="body3" fontWeight="bold">
                                  {participantGroup.label}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  name={`children.${index}.participantGroup`}
                                  size="small"
                                  value={selectedGroups[index]}
                                  getOptionLabel={(option) => option ?? ""}
                                  onChange={(e, group) => {
                                    const newSelectedGroup = [
                                      ...selectedGroups,
                                    ];
                                    newSelectedGroup[index] = group;
                                    setSelectedGroups(newSelectedGroup);
                                    children[index].participantGroup = group;
                                    mainForm.values.children[
                                      index
                                    ].participantGroup = group;
                                  }}
                                  options={groupOptions}
                                  disabled={false}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Participant Group"
                                    />
                                  )}
                                />
                                {!selectedGroups[index] && (
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                    sx={{ whiteSpace: "pre-wrap" }}
                                  >
                                    <ErrorMessage
                                      name={`children.${index}.participantGroup`}
                                    />
                                  </MDTypography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4}></Grid>
                              <Grid item xs={12}></Grid>
                              <Grid item xs={12} sm={2}>
                                <MDTypography variant="body3" fontWeight="bold">
                                  {dob.label}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <DatePicker
                                  name={`children.${index}.dob`}
                                  placeholder="Select Date"
                                  format="yyyy-MM-dd"
                                  onChange={(date) => {
                                    const newSelectedDOBs = [...selectedDOBs];
                                    newSelectedDOBs[index] = date;
                                    setSelectedDOBs(newSelectedDOBs);
                                    children[index].dob = date;
                                    mainForm.values.children[index].dob = date;
                                  }}
                                />
                                {!selectedDOBs[index] && (
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                    sx={{ whiteSpace: "pre-wrap" }}
                                  >
                                    <ErrorMessage
                                      name={`children.${index}.dob`}
                                    />
                                  </MDTypography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4}></Grid>
                              <Grid item xs={12} sm={2}>
                                <MDTypography variant="body3" fontWeight="bold">
                                  {gender.label}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  name={`children.${index}.gender`}
                                  freeSolo
                                  size="small"
                                  //value={genderValue}
                                  options={["Male", "Female"]}
                                  disabled={false}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Gender"
                                    />
                                  )}
                                  getOptionLabel={(option) => option ?? ""}
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  onChange={(event, value) => {
                                    const newSelectedGenders = [
                                      ...selectedGenders,
                                    ];
                                    newSelectedGenders[index] = value;
                                    setSelectedGenders(newSelectedGenders);
                                    children[index].gender = value;
                                    mainForm.values.children[index].gender =
                                      value;
                                  }}
                                />
                                {!selectedGenders[index] && (
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                    sx={{ whiteSpace: "pre-wrap" }}
                                  >
                                    <ErrorMessage
                                      name={`children.${index}.gender`}
                                    />
                                  </MDTypography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4}></Grid>
                            </Grid>
                          </MDBox>
                        ))
                      : null}
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        arrayHelpers.push({
                          participantGroup: null,
                          dob: null,
                          gender: null,
                        });
                        const newSelectedGroups = [...selectedGroups];
                        newSelectedGroups.push(null);
                        setSelectedGroups(newSelectedGroups);
                        const newSelectedDOBs = [...selectedDOBs];
                        newSelectedDOBs.push(null);
                        setSelectedDOBs(newSelectedDOBs);
                        const newSelectedGenders = [...selectedGenders];
                        newSelectedGenders.push(null);
                        setSelectedGenders(newSelectedGenders);
                      }} // insert an empty string at a position
                    >
                      add another child
                    </MDButton>
                  </MDBox>
                </>
              );
            }}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChildrenInformation;
