import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import InputAdornment from "@mui/material/InputAdornment";
//import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

//import { roleOptions } from "pages/settings/user/new/schemas/setups";

function ParamSettings({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  //mainForm.values.param_P = 22;
  const { ASQ3Communication_CutoffLB, ASQ3Communication_CutoffUB } =
    mainFormField;

  return (
    <Card id="cusum-variables" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">
            Settings for 36 months timepoint
          </MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={6} xl={3}>
              <MDTypography variant="body3" fontWeight="bold">
                {ASQ3Communication_CutoffLB.label}
              </MDTypography>
            </Grid>
            <Grid item xs={5} xl={2}>
              <FormTextFieldFast
                form={mainForm}
                field={ASQ3Communication_CutoffLB}
                variant="outlined"
                disabled={modeDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"<="}</InputAdornment>
                  ),
                }}
                /*onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "f0");
                }}*/
              />
            </Grid>
            <Grid item xs={1} xl={1}></Grid>
            <Grid item xs={6} xl={3}>
              <MDTypography variant="body3" fontWeight="bold">
                {ASQ3Communication_CutoffUB.label}
              </MDTypography>
            </Grid>
            <Grid item xs={5} xl={2}>
              <FormTextFieldFast
                form={mainForm}
                field={ASQ3Communication_CutoffUB}
                variant="outlined"
                disabled={modeDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{">="}</InputAdornment>
                  ),
                }}
                /*onChange={(e) => {
                  recalculateCusumSettings(e.target.value, "f0");
                }}*/
              />
            </Grid>
            <Grid item xs={1} xl={1}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ParamSettings;
