import * as Yup from "yup";
import form from "pages/calculator/36months/schemas/form";

const {
  formField: {
    ASQ3Communication_CutoffLB,
    ASQ3Communication_CutoffUB,
    type1Error,
    type2Error,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [ASQ3Communication_CutoffLB.name]: Yup.string()
    .required(ASQ3Communication_CutoffLB.errorMsg)
    .max(200, ASQ3Communication_CutoffLB.invalidMsg),
  [ASQ3Communication_CutoffUB.name]: Yup.string()
    .required(ASQ3Communication_CutoffUB.errorMsg)
    .max(200, ASQ3Communication_CutoffUB.invalidMsg),
  [type1Error.name]: Yup.string()
    .required(type1Error.errorMsg)
    .max(200, type1Error.invalidMsg),
  [type2Error.name]: Yup.string()
    .required(type2Error.errorMsg)
    .max(200, type2Error.invalidMsg),
});

export { validations as default, noValidation };
