import form from "pages/calculator/36months/schemas/form";

const {
  formField: {
    ASQ3Communication_CutoffLB,
    ASQ3Communication_CutoffUB,
    type1Error,
    type2Error,
  },
} = form;

const initialValues = {
  [ASQ3Communication_CutoffLB.name]: "30",
  [ASQ3Communication_CutoffUB.name]: "45",
  [type1Error.name]: "1.2",
  [type2Error.name]: "1.3",
};

export { initialValues as default };
