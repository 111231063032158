import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

//import SubmissionProgress from "components/organisms/SubmissionProgress";
//import PermissionDenied from "components/organisms/PermissionDenied";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";

//import ModeEditIcon from "@mui/icons-material/ModeEdit";
//import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
//import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

//import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

//import { Formik, Form } from "formik";
//import form from "pages/nudges/groupA/schemas/form";
//import NudgeDetails from "pages/nudges/groupA/manage/components/NudgeDetails";

//import { useCalculatorSettingsManager } from "pages/calculator/hooks/useCalculatorSettingsManager";
//import { fi } from "date-fns/locale";

//import initialValues from "pages/nudges/groupA/schemas/initialValues";
//import { List } from "rsuite";
import { Formik, Form } from "formik";
import validations from "pages/calculator/36months/schemas/validations";
import initialValues from "pages/calculator/36months/schemas/initialValues";
import form from "pages/calculator/36months/schemas/form";
import ParamSettings from "pages/calculator/36months/components/ParamSettings";

function Settings36months() {
  //const { retrieveDoc, createDoc, updateDoc, serverTimestamp } = useDocument();
  //const { user } = useAuthContext();
  const handleSubmit = async (values, actions) => {
    try {
      console.log("Handle submit");
    } catch (err) {
      console.log("error");
    } finally {
      console.log("finally");
    }
  };

  const { formId, formField } = form;

  return (
    <DashboardLayout>
      <Topnav title="Settings" /*{modeTitle}*/ />
      <MDBox mb={7}></MDBox>
      {/*(showProgress || response.isPending) && <SubmissionProgress />*/}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form id={formId} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ParamSettings
                  mainForm={formik}
                  mainFormField={formField}
                  modeDisabled={false}
                />
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDBox></MDBox>
                  <MDButton
                    disabled={formik.isSubmitting}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    {"Submit"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default Settings36months;
