const Role = Object.freeze({
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",

  // permission of action (as shown on side nav bar)
  VIEW_SETTINGS_ACTION: "VIEW_SETTINGS_ACTION",
  //VIEW_PRODUCTS_ACTION: "VIEW_PRODUCTS_ACTION",
  //VIEW_DIAGNOSTICTESTS_ACTION: "VIEW_DIAGNOSTICTESTS_ACTION",
  //VIEW_QUESTIONS_ACTION: "VIEW_QUESTIONS_ACTION",
  //VIEW_INVITATIONS_ACTION: "VIEW_INVITATIONS_ACTION",
  //VIEW_STUDENTS_ACTION: "VIEW_STUDENTS_ACTION",
  //VIEW_INVOICES_ACTION: "VIEW_INVOICES_ACTION",
  //VIEW_WORKSHEETS_ACTION: "VIEW_WORKSHEETS_ACTION",
  //VIEW_MYTESTS_ACTION: "VIEW_MYTESTS_ACTION",

  // permission for data (also use for firestore rules)
  PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  // PURGE_ROLE: "PURGE_ROLE",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",
  // PURGE_USER: "PURGE_USER",
  //CREATE_BABY_PROFILE: "CREATE_BABY_PROFILE",
  //SUBMIT_ASSESSMENT: "SUBMIT_ASSESSMENT",

  READ_NUDGE: "READ_NUDGE",
  READ_ALL_NUDGES: "READ_ALL_NUDGES",
  CREATE_NUDGE: "CREATE_NUDGE",
  UPDATE_NUDGE: "UPDATE_NUDGE",
  DELETE_NUDGE: "DELETE_NUDGE",

  READ_PARENT: "READ_PARENT",
  READ_ALL_PARENTS: "READ_ALL_PARENTS",
  CREATE_PARENT: "CREATE_PARENT",
  UPDATE_PARENT: "UPDATE_PARENT",
  DELETE_PARENT: "DELETE_PARENT",

  READ_CHILD: "READ_CHILD",
  READ_ALL_CHILDREN: "READ_ALL_CHILDREN",
  CREATE_CHILD: "CREATE_CHILD",
  UPDATE_CHILD: "UPDATE_CHILD",
  DELETE_CHILD: "DELETE_CHILD",

  READ_36M_RESPONSE: "READ_36M_RESPONSE",
  READ_36M_RESPONSES: "READ_36M_RESPONSES",
  UPDATE_36M_RESPONSE: "UPDATE_36M_RESPONSE",

  SEND_EMAIL: "SEND_EMAIL",

  READ_THRESHOLD: "READ_THRESHOLD",
  READ_ALL_THRESHOLDS: "READ_ALL_THRESHOLDS",
  CREATE_THRESHOLDS: "CREATE_THRESHOLDS",
  UPDATE_THRESHOLDS: "UPDATE_THRESHOLDS",
  DELETE_THRESHOLDS: "DELETE_THRESHOLDS",
});

export { Role, Permission };
