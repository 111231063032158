import { useState, useRef } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import Card from "@mui/material/Card";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";
import SelectParticipant from "pages/participants/manage/components/SelectParticipant";
import ChildrenInformation from "pages/participants/manage/components/ChildrenInformation";
import EditParticipant from "pages/participants/manage/components/EditParticipant";
import Sidenav from "pages/participants/manage/components/Sidenav";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import form from "pages/participants/manage/schemas/form";

import { AllowedTo } from "react-abac";
import { useParticipantsManager } from "pages/participants/hooks/useParticipantsManager";

const _ = require("lodash");

function ManageUsers() {
  const { formId, formField } = form;
  const [parentData, setParentData] = useState(null);
  const childrenData = useRef(null);
  const [childrenIds, setChildrenIds] = useState(null);
  const whatsappDates = useRef(null);
  const whatsappDatesForms = useRef(null);
  const [countRefresh, setCountRefresh] = useState(0);
  const [showEditPanel, setShowEditPanel] = useState(false);

  const {
    modeTitle,
    response,
    modePermission,
    selectedAccount,
    setSelectedAccount,
    submitUpdateNudges,
    submitUpdateFormNudges,
    submitUpdateParentChildDocs,
    submitDeleteParentChildDocs,
    tabValue,
    setTabValue,
    toggleButtonValue,
    setToggleButtonValue,
    activeParentId,
  } = useParticipantsManager();

  const handleDelete = async () => {
    await submitDeleteParentChildDocs(
      parentData.id,
      childrenData.current[tabValue].id
    );
    setParentData(null);
    childrenData.current = null;
    setSelectedAccount(null);
    setShowEditPanel(false);
  };

  const handleSubmit = async (values, actions) => {
    try {
      const date = new Date(values.dob);
      const milliseconds = date.getTime();
      const seconds = Math.floor(milliseconds / 1000);
      const nanoseconds = (milliseconds - seconds * 1000) * 1000000;
      const deltaSeconds =
        seconds - parentData.data.children[tabValue].dob.seconds;

      // Update parentData
      parentData.data.mobile = values.mobile;
      parentData.data.children[tabValue].dob.seconds = seconds;
      parentData.data.children[tabValue].dob.nanoseconds = nanoseconds;
      parentData.data.children[tabValue].gender = values.gender;

      // Update dob, nudges and formnudges in childrenData
      childrenData.current[tabValue].data.dob.seconds = seconds;
      childrenData.current[tabValue].data.dob.nanoseconds = nanoseconds;
      const nudges = _.cloneDeep(childrenData.current[tabValue].data.nudges);
      nudges.forEach((elm) => {
        // A bug when setting the flag "Sent" after sending WhatsApp. Require patching.
        if (elm.whatsappStatus !== "Sent" && elm.whatsappStatus !== " Sent")
          elm.date.seconds = elm.date.seconds + deltaSeconds;
      });
      childrenData.current[tabValue].data.nudges = nudges;

      const formnudges = _.cloneDeep(
        childrenData.current[tabValue].data.formnudges
      );
      formnudges.forEach((elm, index) => {
        if (
          index >
            2 /* The first 3 forms [index: 0-2] are sent upon enrolment*/ &&
          elm.whatsappStatus !== "Sent" &&
          elm.whatsappStatus !== " Sent"
        )
          elm.date.seconds = elm.date.seconds + deltaSeconds;
      });
      childrenData.current[tabValue].data.formnudges = formnudges;
      childrenData.current[tabValue].data.gender = values.gender;
      setCountRefresh(countRefresh + 1);

      // Update "parents" and "children" documents
      await submitUpdateParentChildDocs(
        parentData,
        tabValue,
        childrenData.current[tabValue]
      );
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={3}>
              <MDBox>
                {response.error?.name !== "OperationInvalidError" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}>
                      <Sidenav />
                    </Grid>
                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <SelectParticipant
                          setParentData={setParentData}
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          childrenData={childrenData}
                          whatsappDates={whatsappDates}
                          whatsappDatesForms={whatsappDatesForms}
                          countRefresh={countRefresh}
                          setCountRefresh={setCountRefresh}
                          setShowEditPanel={setShowEditPanel}
                          handleDelete={handleDelete}
                        />
                      </MDBox>
                      {!!parentData && showEditPanel && (
                        <MDBox mb={3}>
                          <Card
                            id="edit-participant"
                            sx={{ overflow: "visible" }}
                          >
                            <Formik
                              enableReinitialize
                              initialValues={{
                                mobile: parentData.data.mobile,
                                whatsappInvite: "",
                                participantGroup:
                                  parentData.data.children[0].participantGroup,
                                dob: parentData.data.children[0].dob.toDate(),
                                gender: parentData.data.children[0].gender,
                              }}
                              validationSchema={Yup.object().shape({
                                mobile: Yup.string()
                                  .required("Mobile number is required.")
                                  .min(
                                    10,
                                    "Please enter a valid mobile number"
                                  ),
                                dob: Yup.string()
                                  .nullable()
                                  .required("DOB is required"),
                                gender: Yup.string()
                                  .nullable()
                                  .required("Please indicate the gender")
                                  .min(1, "Please indicate the gender"),
                              })}
                              onSubmit={handleSubmit}
                            >
                              {(formik) => (
                                <Form id={formId} autoComplete="off">
                                  <EditParticipant
                                    mainForm={formik}
                                    mainFormField={formField}
                                    parentData={parentData}
                                    setShowEditPanel={setShowEditPanel}
                                  />
                                  <MDBox m={2}>
                                    <MDBox
                                      width="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <MDBox></MDBox>
                                      <MDButton
                                        disabled={formik.isSubmitting}
                                        type="submit"
                                        variant="gradient"
                                        color="dark"
                                      >
                                        {"Update"}
                                      </MDButton>
                                    </MDBox>
                                  </MDBox>
                                </Form>
                              )}
                            </Formik>
                          </Card>
                        </MDBox>
                      )}
                      {!!parentData && (
                        <MDBox mb={3}>
                          <ChildrenInformation
                            parentData={parentData}
                            submitUpdateNudges={submitUpdateNudges}
                            submitUpdateFormNudges={submitUpdateFormNudges}
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            toggleButtonValue={toggleButtonValue}
                            setToggleButtonValue={setToggleButtonValue}
                            activeParentId={activeParentId}
                            childrenData={childrenData}
                            childrenIds={childrenIds}
                            setChildrenIds={setChildrenIds}
                            whatsappDates={whatsappDates}
                            whatsappDatesForms={whatsappDatesForms}
                            countRefresh={countRefresh}
                          />
                        </MDBox>
                      )}
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </MDBox>
            <DashboardFooter />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default ManageUsers;
